import '../styles/index.scss';
import 'bootstrap';

window.$ = window.jQuery = require('jquery');
const fancybox = require('@fancyapps/fancybox');
const slick = require("slick-carousel");
const rangeSlider = require("./ion.rangeSlider");
const select2 = require("select2");

// Временный костыль
// window.onload = function() {
//     // if (screen.width < 1920) {
//     //     var mvp = document.getElementById('vp');
//     //     mvp.setAttribute('content','user-scalable=no,width=1920');
//     // }
// };

// var background = document.getElementById('background');
// var parallaxInstance = new Parallax(background);

$('body').on('click', '.question__icon img', function () {
    var content = $(this).parent().find('.question__content');
    if (content) {
        $("#questionIconContent").html(content.html());
        $('#infoModal').modal('show');
    }
});

$("#reviews").slick({
    infinite: true,
    dots: true,
    slidesToShow: 3,
    responsive: [{
            breakpoint: 1024,
            settings: {
                dots: true,
                arrows: false
            }
        },
        {
            breakpoint: 768,
            settings: {
                dots: true,
                arrows: false,
                slidesToShow: 1,
            }
        }
    ]
});

var priceItemSettings = {
    infinite: false,
    dots: true,
    slidesToShow: 3,
    responsive: [{
            breakpoint: 1025,
            settings: {
                dots: true,
                slidesToShow: 2,
                arrows: true
            }
        },
        {
            breakpoint: 762,
            settings: {
                dots: true,
                slidesToShow: 1,
                arrows: false
            }
        }
    ]
};

$('#prices').slick(priceItemSettings);
$('#additional-blocks').slick(priceItemSettings);

$('body').on('click', '.calculator-item__hidden-title', function () {
    // $('.calculator-item').addClass('calculator-item--hidden');
    $('.calculator-item:not(.calculator-item--add)').addClass('calculator-item--hidden');
    $(this).parents('.calculator-item').removeClass('calculator-item--hidden');
});

$('body').on('click', '.calculator-item__delete', function () {
    $(this).parents('.calculator-item').remove();
    calcPrice();
});

$(function () {
    $('body').on('select2:select', '.js-select', function () {
        calcPrice();
    });

    $('#addTent').change(function () {
        calcPrice();
    });

    $('.callback-button').click(function () {
        $("#callbackModal").modal('show');
        $("#formName").val($(this).data('form_name'));
    });

    $('body').on('change', '.calculator-item input', function () {
        calcPrice();
    });

    $('.calculator-items').prepend($("#calculator_html").html());

    $("#calculator_buy").click(function () {
        sendForm();
    });
    updateInputs();
    calcPrice();


    //отправка форм
    $(".loadForm").submit(function (event) {
        event.preventDefault();
        var $form = $(this);

        $.ajax({
            type: "POST",
            url: "/orders.php",
            data: {
                phone: $('#inputPhone').val(),
                form_type: $('#formName').val()
            },
            success: function (data) {
                alert(data);
                $('#callbackModal').modal('hide');
            },
            error: function (e) {
                alert("Что-то пошло не так! Сообщение не было отравлено.");
                console.log(e);
                $('#callbackModal').modal('hide');
            }
        });
        return false;
    });
});

function updateInputs() {
    $('.js-select').select2({
        minimumResultsForSearch: -1
    });

    $(".js-range-slider").ionRangeSlider({
        skin: "round",
        min: 0,
        max: 50,
        from: 5,
        grid: true,
        grid_num: 5,
        hide_min_max: true,
        onUpdate: function (data) {
            var el = data.slider;
            updateSlider(el);
        },
        onChange: function (data) {
            var el = data.slider;
            updateSlider(el);
        }
    });

    $('.js-range-slider-time').ionRangeSlider({
        skin: "round",
        min: 0,
        max: 5,
        from: 1,
        grid: true,
        grid_num: 5,
        hide_min_max: true,
        onUpdate: function (data) {
            var el = data.slider;
            updateSlider(el);
        },
        onChange: function (data) {
            var el = data.slider;
            updateSlider(el);
        }
    });
}

function updateSlider(el) {
    var cursorLeft = parseFloat(el.find(".irs-handle").css("left"), 10);
    if (cursorLeft != NaN) {
        $.each(el.find('.irs-grid-pol'), function () {
            var pointLeft = parseFloat($(this).css("left"), 10);
            if (pointLeft <= cursorLeft) {
                $(this).addClass('irs-grid-pol--done');
            } else {
                $(this).removeClass('irs-grid-pol--done');
            }
        });
    }
    calcPrice();
}

function formChange() {
    $.each($('.calculator-item'), function (i, block) {
        var gameName = $(block).find('[name=gameType]').val();
        $(block).find('.calculator-item__hidden-title').html(gameName);
        if (gameName) {
            var gameType = '';
            if (gameName == 'пейнтбол') gameType = 'paintball';
            if (gameName == 'детский пейнтбол') gameType = 'childpaintball';
            if (gameName == 'лазертаг') gameType = 'lasertag';

            $(block).find('.paintball, .childpaintball, .lasertag').hide();

            if (gameType == 'paintball') {
                $(block).find('.gameQuestion').html("Пейнтбол - командная игра с применением маркеров, стреляющих шариками с краской, разбивающими при ударе о препятсвие и окрашивающими его.");
            }
            if (gameType == 'lasertag') {
                $(block).find('.gameQuestion').html("Лазертаг —активная игра, суть которой заключается в поражении игроков и специальных интерактивных мишеней безопасными «электронными» выстрелами. Попадание засчитывается, когда луч из оружия соперника попадает на сенсоры, закреплённые на амуниции игрока. Абсолютная безопасность и максимальное приближение к условиям реального боя.");
            }
            if (gameType == 'childpaintball') {
                $(block).find('.gameQuestion').html("Главные отличия от стандартного оборудования - облегченный вес, уменьшенный размер шарика с краской и пониженная скорость выстрела.");
            }

            $(block).find('.' + gameType).show();
        }
    });
}

function sendForm() {
    var calcInfo = calcPrice();
    if ($('#name').val() == '') {
        alert('Не введено имя');
        return;
    }

    if ($('#phone').val() == '') {
        alert('Не введен телефон');
        return;
    }

    var data = {
        items: calcInfo.items,
        total: calcInfo.total,
        name: $('#name').val(),
        phone: $('#phone').val(),
        email: $('#email').val(),
        card: $('#cardCode').val()
    };

    $.post('/orders.php', data, function (resp) {
        alert(resp);
    });
}

function calcPrice() {
    formChange();
    var totalPrice = 0;
    var priceItems = [];
    $.each($('.calculator-item'), function (i, block) {
        if ($(block).hasClass('calculator-item--add')) {
            return;
        }
        var playersAmt = $(block).find('[name=playersAmt]').val();
        var childPlayersAmt = $(block).find('[name=childPlayersAmt]').val();
        var gameType = $(block).find('[name=gameType]').val();
        var ballsCount = $(block).find('[name=ballsCount]').val() * 1.0;
        var noiseGrenade = $(block).find('[name=noiseGrenade]').val() * 1.0;
        var smokeGrenade = $(block).find('[name=smokeGrenade]').val() * 1.0;
        var playTime = $(block).find('[name=playTime]').val() * 1.0;
        var priceItem = {
            noiseGrenade: noiseGrenade,
            smokeGrenade: smokeGrenade,
            gameType: gameType
        };
        if (gameType == 'пейнтбол') {
            var ballPrice = 700 / 500;
            var playerPrice = 800;
            if (playersAmt >= 15) playerPrice = 700;
            var pricePerPlayer = playerPrice + ballsCount * ballPrice;

            var childPlayerPrice = 450;
            if (childPlayersAmt >= 15) childPlayerPrice = 400;
            var pricePerChildPlayer = childPlayerPrice + ballsCount * ballPrice;
            var itemPrice = 0;
            itemPrice += pricePerPlayer * playersAmt + pricePerChildPlayer * childPlayersAmt;
            itemPrice += noiseGrenade * 300 + smokeGrenade * 300;

            if ($(block).find('[name=addLong]').is(':checked')) {
                itemPrice += (100 * $(block).find('[name=longCount]').val() * 1.0) || 0;
                priceItem.longCount = $(block).find('[name=longCount]').val();
            }
            if ($(block).find('[name=addMarker]').is(':checked')) {
                itemPrice += (400 * $(block).find('[name=markerCount]').val() * 1.0) || 0;
                priceItem.markerCount = $(block).find('[name=markerCount]').val();
            }
            if ($(block).find('[name=addCloth]').is(':checked')) {
                itemPrice += (300 * $(block).find('[name=clothCount]').val() * 1.0) || 0;
                priceItem.clothCount = $(block).find('[name=clothCount]').val();
            }
            if ($(block).find('[name=addMask]').is(':checked')) {
                itemPrice += (200 * $(block).find('[name=maskCount]').val() * 1.0) || 0;
                priceItem.maskCount = $(block).find('[name=maskCount]').val();
            }

            priceItem.playersAmt = playersAmt,
                priceItem.childPlayersAmt = childPlayersAmt,
                priceItem.total = itemPrice;
            totalPrice += itemPrice;
        }
        if (gameType == 'лазертаг' || gameType == 'детский пейнтбол') {
            var hourPrice = 800;
            if (playTime >= 2) hourPrice = 600;
            if (playTime >= 3) hourPrice = 500;
            if (playersAmt >= 15) {
                hourPrice = 700;
                if (playTime >= 2) hourPrice = 550;
                if (playTime >= 3) hourPrice = 470;
            }
            var itemPrice = 0;

            itemPrice += hourPrice * playTime * playersAmt;
            itemPrice += noiseGrenade * 300 + smokeGrenade * 300;
            if (gameType == 'лазертаг' && $(block).find('[name=addCloth]').is(':checked')) {
                itemPrice += (300 * $(block).find('[name=clothCount]').val() * 1.0) || 0;
                priceItem.clothCount = $(block).find('[name=clothCount]').val();
            }

            priceItem.playersAmt = playersAmt;
            priceItem.playTime = playTime;
            priceItem.total = itemPrice;

            totalPrice += itemPrice;
        }

        priceItems.push(priceItem);
    });

    if ($('#addTent').is(':checked')) {
        var tentPrice = 3000;
        var month = new Date().getMonth();
        if (month == 11 || month <= 1) {
            tentPrice = 3500;
        }
        totalPrice += tentPrice;
    }

    totalPrice = Math.floor(totalPrice);
    var currentPrice = parseInt($('#priceValue').html());
    var el = $('#priceValue').html(totalPrice || 0);
    // $({ Counter: currentPrice }).animate({ Counter:totalPrice }, {
    // 	duration: 500,
    // 	easing: 'swing',
    // 	step: function () {
    // 	  el.text(Math.ceil(this.Counter));
    // 	}
    // });

    return {
        total: totalPrice,
        items: priceItems
    };
}

$.ajax({
    url: 'https://api.vk.com/method/wall.get?domain=militarypark&v=5.126&count=4&filter=all&access_token=da649714da649714da649714f6da12ce15dda64da649714ba46e0a07be64739f04df4a7',
    type: 'GET',
    dataType: 'jsonp',
    crossDomain: true,
    success: function (resp) {
        console.log(resp);
        if (resp.response) {
            resp.response.items.forEach(function (item, i) {
                var html_item = $('.news-item')[i];
                if (html_item) {
                    var url = '/public/images/vk4.jpg';
                    var attachment = item.attachments.find(item => item.type == 'photo');
                    if (attachment) {
                        try {
                            var url = attachment.photo.sizes[attachment.photo.sizes.length - 1].url
                        } catch (error) {}
                    }
                    var el = $(html_item);
                    el.find('a').attr('href', 'https://vk.com/militarypark?w=wall-150149385_' + item.id + '%2Fall');
                    el.find('.item').attr('style', 'background-image: url(' + url + ');');
                    var heading = item.text.split(/\.|\?|\!/)[0];
                    var links = heading.match(/\[.*.]/, '');
                    console.log(links);
                    if (links) {
                        links.forEach(function (l) {
                            var nrm = l.replace('[', '').replace(']', '');
                            var str = nrm.split('|')[1];
                            heading = heading.replace(l, str);
                        })
                    }
                    var text = item.text.replace(heading, '');
                    text = text.slice(0, 400);
                    var links = text.match(/\[.*.]/, '');
                    if (links) {
                        links.forEach(function (l) {
                            var nrm = l.replace('[', '').replace(']', '');
                            var str = nrm.split('|')[1];
                            text = text.replace(l, str);
                        })
                    }
                    el.find('.item__title').html(heading)
                    el.find('.item__short').html(text + '...')
                    console.log(el, url)
                }
            })
        }
    }
});

$.get('https://www.instagram.com/garnizon_park', function (data) {
    var image_array = [];

    try {
        data = data.split("window._sharedData = ")[1].split("<\/script>")[0];
        data = JSON.parse(data.substr(0, data.length - 1));
        data = data.entry_data.ProfilePage || data.entry_data.TagPage;
        data = data[0].graphql.user || data[0].graphql.hashtag;
        var imgs = (data.edge_owner_to_timeline_media || data.edge_hashtag_to_media).edges;
        var max = 8;
        imgs.forEach((item, i) => {
            if (max < (i + 1)) return;

            var url = "https://www.instagram.com/p/" + item.node.shortcode,
                image;

            image = item.node.thumbnail_src;

            if (url && image) {
                image_array.push({
                    url,
                    image
                });
            }
        });
    } catch (error) {
        image_array = [{
                url: 'https://www.instagram.com/garnizon_park/',
                image: '/public/images/polygon_01.jpg'
            },
            {
                url: 'https://www.instagram.com/garnizon_park/',
                image: '/public/images/polygon_01.jpg'
            },
            {
                url: 'https://www.instagram.com/garnizon_park/',
                image: '/public/images/polygon_01.jpg'
            },
            {
                url: 'https://www.instagram.com/garnizon_park/',
                image: '/public/images/polygon_01.jpg'
            },
            {
                url: 'https://www.instagram.com/garnizon_park/',
                image: '/public/images/polygon_01.jpg'
            },
            {
                url: 'https://www.instagram.com/garnizon_park/',
                image: '/public/images/polygon_01.jpg'
            },
            {
                url: 'https://www.instagram.com/garnizon_park/',
                image: '/public/images/polygon_01.jpg'
            },
            {
                url: 'https://www.instagram.com/garnizon_park/',
                image: '/public/images/polygon_01.jpg'
            },
        ];
    };

    $('.insta-gallery').html("");

    image_array.forEach(image => {
        $('.insta-gallery').append(`
			<div class="insta-gallery__photo">
				<a href="${image.url}" target="_blank">
					<img src="${image.image}" alt="">
				</a>
			</div>
		`);
    });
});

var calculator_items = [];

$('#addCalculatorItem').click(function () {
    $('.calculator-item:not(.calculator-item--add)').addClass('calculator-item--hidden');
    $('.calculator-items').prepend($("#calculator_html").html());
    updateInputs();
    formChange();
    calcPrice();
});